import * as React from "react";
import "./home.scss";

interface HomeState {
  source: any,
}
export interface HomeProps {
  source: any,
}

class Home extends React.Component<HomeProps, HomeState> {
  constructor(props: HomeProps) {
    super(props);
    this.toggleSource = this.toggleSource.bind(this);
    this.state = {
      source: this.props.source,
    };
  }

  toggleSource(source:any) {
    this.state.source(source);
  }

  render() {
    return (<div className="home">
        <h1>Light The Deep</h1>
        <div className="quick-overview">
          <p>Light the Deep is the musical moniker of David Brooks, electronic musician and visual artist.</p>
        </div>
        <div className="podcast-overview">
          <h2>Music From The Depths Podcast</h2>
          <p>Started in May of 2020, Music From the Depths is a stream of original music that explores a variety of genres.</p>
          <p>The podcast is free to listen, wherever you subscribe to podcasts.</p>
        </div>
        <div className="songs-overview">
          <h2>Songs &amp; Releases</h2>
          <p>
            Details coming soon.
          </p>
        </div>
      </div>
    )
  }
};

export {Home};
