import * as React from "react";
import "./art.scss";
import {ActiveSong, Subscriber} from "../data-manager/data-manager";

interface ArtState {
  podcast: [],
  songs: [],
  thumbnail: string,
  title: string,
}

export interface ArtProps {
}

class Art extends React.Component<ArtProps, ArtState> {
  constructor(props: ArtProps) {
    super(props);
    this.state = {
      podcast: [],
      songs: [],
      thumbnail: "",
      title: "",
    };
  }

  componentDidMount() {
    Subscriber.subscribe((v:any) => {
      if (typeof v == 'string') {
        v = JSON.parse(v);
      }
      if (v) {
        this.setState({ podcast: v.podcast }, () => {});
        this.setState({ songs: v.songs }, () => {});
      }
    })
    ActiveSong.subscribe((v) => {
      if (v !== '') {
        let currentSongs:any = this.state.songs;
        let currentEpisodes:any = this.state.podcast;
        let title;
        let thumbnail;
        for (let i = 0; i < currentSongs.length; i++) {
          if (currentSongs[i].guid === v) {
            thumbnail = currentSongs[i].itunes.image;
            title = currentSongs[i].title;
            this.setState({title: title});
            this.setState({thumbnail: thumbnail});
          }
        }
        for (let i = 0; i < currentEpisodes.length; i++) {
          if (currentEpisodes[i].guid === v) {
            thumbnail = currentEpisodes[i].itunes.image;
            title = currentEpisodes[i].title;
            this.setState({title: title});
            this.setState({thumbnail: thumbnail});
          }
        }
      }
    })
  }

  render() {
    return (<div className="art" style={ { backgroundImage: `url(${ this.state.thumbnail })` } }></div>
    )
  }
};

export {Art};
