import { BehaviorSubject } from 'rxjs';

const ActiveSong = new BehaviorSubject("");
const Subscriber = new BehaviorSubject({});

const DataManager = {
  send: function(data:any) {
    Subscriber.next(data)
  },
  load: function() {
    let podcastURL = 'https://feeder.apps.northwardcompass.com/feeds/mftd-podcast';
    let data = {
      podcast: [],
      songs: []
    };
    fetch(podcastURL)
      .then(async response => {
            const da = await response.json();
            data.podcast = da.items;
            this.saveStorage("ltd:d", JSON.stringify(data));
            this.pullStorage("ltd:d");
        })
        .catch(error => {
            console.error('There was an error!', error);
        });

  },
  pullStorage: function(key:string) {
    let query = localStorage.getItem(key);
    let placeholder = {
      projects: []
    }
    if (query == null) {
      this.saveStorage(key, JSON.stringify(placeholder));
    }
    let current = localStorage.getItem(key);
    let result = placeholder;
    if (current != null) {
      result = JSON.parse(current);
    }
    this.send(result);

  },
  saveStorage: function(key:string, data:string) {
    localStorage.setItem(key, data);
    this.send(localStorage.getItem(key));
  },
  getActive: function() {
    if (localStorage.getItem("ltd:as") == null) {
      localStorage.setItem("ltd:as", "0");
    }
  },
  setActive: function(guid: string) {
    localStorage.setItem("ltd:as", guid);
    let returnId = localStorage.getItem("ltd:as");
    let response = "";
    if (returnId != null) {
      response = returnId;
    }
    ActiveSong.next(response);
  }
};

export {
  ActiveSong,
  DataManager,
  Subscriber
}
