import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Content } from "./components/content/Content";
import { Sidebar } from "./components/sidebar/sidebar";
import {DataManager, Subscriber} from "./components/data-manager/data-manager";

interface AppState {
  activeState: null | string,
  menuState: boolean,
}

interface AppProps {}

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.setMenuState = this.setMenuState.bind(this);
    this.setFeatureState = this.setFeatureState.bind(this);
    this.state = {
      activeState: null,
      menuState: false,
    };
  }

  setMenuState() {
    this.setState(prevState => ({menuState: !prevState.menuState}));
  }

  setFeatureState(feature: any) {
    this.setState({activeState: feature});
    this.setMenuState();
  }

  componentDidMount() {
    DataManager.load();
    Subscriber.subscribe((v) => {})
  }

  render() {
    return(<div className={"app-wrapper " + (this.state.menuState ? 'nav-open': 'nav-closed') + ' ' + (this.state.activeState + '_active')}>
      <Router>
        <Sidebar setFeature={this.setFeatureState}></Sidebar>
        <div className="app-space">
          <Content active={this.state.activeState}></Content>
        </div>
      </Router>
    </div>);
    };
};
export {App};
