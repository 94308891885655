import * as React from "react";
import "./dashboard.scss";
import {Header} from "../header/Header";
import {Art} from "../art/Art";
import {Home} from "../Home/Home";
import {Song} from "./song";
import {CurrentSong} from "./currentSong";
import {ActiveSong, Subscriber} from "../data-manager/data-manager";

interface DashboardState {
  activeSong: string,
  currentSource: string,
  description: string,
  podcast: any[],
  showMenu: boolean,
  songs: any[],
  thumbnail: string,
}

interface DashboardProps {}

class Dashboard extends React.Component<DashboardProps, DashboardState> {
  constructor(props: DashboardProps) {
    super(props);
    this.setMenu = this.setMenu.bind(this);
    this.setSource = this.setSource.bind(this);
    this.state = {
      activeSong: "",
      currentSource: "home",
      description: "",
      podcast: [],
      showMenu: true,
      songs: [],
      thumbnail: "",
    }
  }

  componentDidMount() {
    ActiveSong.subscribe((v) => {
      let currentSongs:any = this.state.songs;
      for (let i = 0; i < currentSongs.length; i++) {
        if (currentSongs[i].guid === v) {
          this.setState({thumbnail: currentSongs[i].thumbnail});
          this.setState({description: currentSongs[i].itunes.summary});
        }
      }
      let currentEpisodes:any = this.state.podcast;
      for (let i = 0; i < currentEpisodes.length; i++) {
        if (currentEpisodes[i].guid === v) {
          this.setState({thumbnail: currentEpisodes[i].thumbnail});
          this.setState({description: currentEpisodes[i].itunes.summary});
        }
      }
    })

    Subscriber.subscribe((v:any) => {
      if (typeof v == 'string') {
        v = JSON.parse(v);
      }
      if (v) {
        this.setState({ podcast: v.podcast }, () => {});
        this.setState({ songs: v.songs }, () => {});
      }
    })
  }
  componentWillUnmount() {
    //ActiveSong.unsubscribe();
    //Subscriber.unsubscribe();
  }

  setMenu(receipt: boolean) {
    this.setState({showMenu: receipt});
  }

  setSource(receipt: string) {
    this.setState({currentSource: receipt});
  }

  render() {
    let episodes;
    if (this.state.podcast) {
      episodes = this.state.podcast.map((item, index) => {
        return <Song key={index} {...item} />
      })
    }
    let songsList;
    if (this.state.songs) {
      songsList = this.state.songs.map((item, index) => {
        return <Song key={index} {...item} />
      })
    }

    return (
      <div className="dashboard">
        <Header menu={this.setMenu} source={this.setSource}></Header>
        <Art></Art>
        <div className={this.state.showMenu ? 'active play-stuff': 'play-stuff'}>
          <div className={this.state.currentSource === "home" ? 'active home-page' : 'home-page'}>
            <Home source={this.setSource}></Home>
          </div>
          <div className={this.state.currentSource === "podcast" ? 'active podcast-list': 'podcast-list'}>
            <div className="podcast-overview">
              <h1>Music From The Depths</h1>
              <p>Every month I’ll be releasing at least two mixes of original music. To do that, I’ll dig into the music I made a long time ago, and some that I’ve written more recently. I’ll probably have an occasional guest, too. Style-wise, these will often be ambient, atmospheric, soundscapes. But I also have a weak spot for intricate, glitchy, granular drums. And since I’ve written in a lot of styles, there’s no telling where each mix will go.</p>
              <p>Subscribe at LightTheDeep.com</p>
            </div>
            <div className="music">
              <h2>Available Episodes</h2>
              {episodes}
            </div>
            <div className={this.state.description !== "" ? 'available description' : 'description'}>
              <div className="inner">
                <h3>About This Episode</h3>
                <p>{this.state.description}</p>
              </div>
            </div>
          </div>
          <div className={this.state.currentSource === "songs" ? 'active songs-list': 'songs-list'}>
            <div className="song-overview">
              <h1>Songs &amp; Albumns</h1>
              <p>Coming soon!</p>
            </div>
            <div className="music">
              {songsList}
            </div>
          </div>
        </div>
        <div className="now-playing">
            <CurrentSong guid={this.state.activeSong} title="Unknown" />
        </div>
      </div>
    )
  }
};

export {Dashboard};
