import * as React from "react";
import "./song.scss";
import {ActiveSong, DataManager} from "../data-manager/data-manager";

interface SongState {
  guid: string,
  isActive: boolean,
  title: string,
}

export interface SongProps {
  guid: string,
  title: string,
}

class Song extends React.Component<SongProps, SongState> {
  constructor(props: SongProps) {
    super(props);
    this.callSelect = this.callSelect.bind(this);
    this.state = {
      guid: this.props.guid,
      isActive: false,
      title: this.props.title
    };
  }

  componentDidMount() {
    ActiveSong.subscribe((v) => {
      if (v === this.state.guid) {
        this.setState({isActive: true});
      } else {
        this.setState({isActive: false});
      }
    })
  }
  callSelect() {
    DataManager.setActive(this.state.guid);
  }

  render() {
    return (
      <div className={this.state.isActive ? 'song active': 'song'} onClick={this.callSelect}>
        <h3 className="song-name">{this.state.title}</h3>
      </div>
    )
  }
};

export {Song};
