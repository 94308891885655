import * as React from "react";
import "./song.scss";

import {ActiveSong, DataManager, Subscriber} from "../data-manager/data-manager";

interface SongState {
  canPlayTrack: boolean,
  guid: string,
  isActive: boolean,
  mp3: string,
  player: any,
  playing: boolean,
  podcast: [],
  shouldAutoPlay: boolean,
  songs: [],
  title: string,
  thumbnail: string,
  width: number,
}

export interface SongProps {
  guid: string,
  title: string,
}

class CurrentSong extends React.Component<SongProps, SongState> {
  constructor(props: SongProps) {
    super(props);
    this.autoPlayNext = this.autoPlayNext.bind(this);
    this.callNext = this.callNext.bind(this);
    this.callPlay = this.callPlay.bind(this);
    this.callPause = this.callPause.bind(this);
    this.callPrevious = this.callPrevious.bind(this);
    this.callTimeUpdate = this.callTimeUpdate.bind(this);
    this.canPlay = this.canPlay.bind(this);
    this.state = {
      canPlayTrack: false,
      guid: this.props.guid,
      isActive: false,
      mp3: "",
      player: "",
      playing: false,
      podcast: [],
      shouldAutoPlay: false,
      songs: [],
      thumbnail: "",
      title: this.props.title,
      width: 0,
    };
  }

  componentDidMount() {
    Subscriber.subscribe((v:any) => {
      if (typeof v == 'string') {
        v = JSON.parse(v);
      }
      if (v) {
        this.setState({ podcast: v.podcast }, () => {});
        this.setState({ songs: v.songs }, () => {});
      }
    })
    ActiveSong.subscribe((v) => {
      if (v !== '') {
        this.setState({canPlayTrack: false});
        this.setState({ isActive: true }, () => {
          let currentSongs:any = this.state.songs;
          let currentEpisodes:any = this.state.podcast;
          let mp3;
          let title;
          let thumbnail;
          for (let i = 0; i < currentSongs.length; i++) {
            if (currentSongs[i].guid === v) {
              mp3 = currentSongs[i].enclosure.url;
              thumbnail = currentSongs[i].itunes.image;
              title = currentSongs[i].title;
              let player:any = document.getElementsByClassName("audio-element")[0];
              player.src = mp3;
              this.setState({guid : v});
              this.setState({mp3: mp3});
              this.setState({title: title});
              this.setState({thumbnail: thumbnail});
              this.setState({width: 0});
              this.callPause();
            }
          }
          for (let i = 0; i < currentEpisodes.length; i++) {
            if (currentEpisodes[i].guid === v) {
              mp3 = currentEpisodes[i].enclosure.url;
              thumbnail = currentEpisodes[i].itunes.image;
              title = currentEpisodes[i].title;
              let player:any = document.getElementsByClassName("audio-element")[0];
              player.src = mp3;
              this.setState({guid : v});
              this.setState({mp3: mp3});
              this.setState({title: title});
              this.setState({thumbnail: thumbnail});
              this.setState({width: 0});
              this.callPause();
            }
          }
        });
      }
    })
  }

  autoPlayNext() {
    //let player:any = document.getElementsByClassName("audio-element")[0];
    this.callNext();
  }

  findClose(prox: number) {
    let currentSongs:any = this.state.songs;
    let currentEpisodes:any = this.state.podcast;
    for (let i = 0; i < currentEpisodes.length; i++) {
      if (this.state.guid === currentEpisodes[i].guid) {
        if (currentEpisodes[i + prox]) {
          return currentEpisodes[i + prox].guid;
        } else {
          if (prox === 1) {
            return currentEpisodes[0].guid;
          } else {
            return currentEpisodes[currentEpisodes.length - 1].guid;
          }
        }
      }
    }
    for (let i = 0; i < currentSongs.length; i++) {
      if (this.state.guid === currentSongs[i].guid) {
        if (currentSongs[i + prox]) {
          return currentSongs[i + prox].guid;
        } else {
          if (prox === 1) {
            return currentSongs[0].guid;
          } else {
            return currentSongs[currentSongs.length - 1].guid;
          }
        }
      }
    }
  }

  callNext() {
    let next = this.findClose(-1);
    if (next) {
      DataManager.setActive(next);
    }
  }

  callPrevious() {
    let next = this.findClose(1);
    if (next) {
      DataManager.setActive(next);
    }
  }

  callPlay() {
    let player:any = document.getElementsByClassName("audio-element")[0];
    player.play();
    this.setState({playing: true});
    this.setState({shouldAutoPlay: true});
  }
  callPause() {
    let player:any = document.getElementsByClassName("audio-element")[0];
    player.pause();
    this.setState({playing: false});
  }
  callTimeUpdate(track: any) {
    let currentTime = track.target.currentTime;
    let duration = track.target.duration;
    let width = (currentTime / duration) * 100;
    this.setState({width: width});
  }

  canPlay() {
      this.setState({canPlayTrack: true});
      if (this.state.shouldAutoPlay === true) {
        this.callPlay();
      }
  }
  render() {
    return (
      <div className={this.state.isActive ? 'active active-song': 'active-song'}>
        <div className="time-marker">
          <div className="listened" style={ { width: `${ this.state.width }%` } }></div>
        </div>
        <div className="song-details">
          <img src={this.state.thumbnail} alt="" />
          <h3 className="song-name">
            {this.state.title}
          </h3>
        </div>
        <div className={this.state.canPlayTrack ? 'active play-controls' : 'play-controls' }>
          <button onClick={this.callPrevious} className="previous">Back</button>
          <button onClick={this.callPlay} className={this.state.playing ? 'hidden play': 'play'}>Play</button>
          <button onClick={this.callPause} className={this.state.playing ? 'pause': 'hidden pause'}>Pause</button>
          <button onClick={this.callNext} className="next">Next</button>
        </div>
        <audio autoPlay className="audio-element" onEnded={this.autoPlayNext} onCanPlay={this.canPlay} onTimeUpdate={this.callTimeUpdate}>
          <source src={this.state.mp3} type="audio/mpeg"></source>
        </audio>
      </div>
    )
  }
};

export {CurrentSong};
