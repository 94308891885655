import * as React from "react";
import "./header.scss";

interface HeaderState {
  currentSource: string,
  menu: any,
  navState: boolean,
  source: any,
}

export interface HeaderProps {
  menu: any,
  source: any,
}

class Header extends React.Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleSource = this.toggleSource.bind(this);
    this.state = {
      currentSource: "home",
      menu: this.props.menu,
      navState: true,
      source: this.props.source,
    };
  }
  toggleMenu() {
    this.setState(prevState => ({navState: !prevState.navState}), () => {
      this.state.menu(this.state.navState);
    });
  }
  toggleSource(source:any) {
    this.setState({currentSource: source}, () => {
      this.state.source(this.state.currentSource);
    });
  }
  render() {
    return (<div className={this.state.navState ? "active branding-and-navigation" : "branding-and-navigation" }>
      <div className="menu-toggle">
        <button onClick={this.toggleMenu} className={this.state.navState ? "active" : "" }>
          <span className="offState">Show Menu</span>
          <span className="onState">Hide Menu</span>
        </button>
      </div>
      <div className={this.state.currentSource === "home" ? "nav-back" : "open nav-back" }>
        <button onClick={() => this.toggleSource("home")}>Back</button>
      </div>
      <div className="navigation">
        <button className={this.state.currentSource === "podcast" ? "active" : "" } onClick={() => this.toggleSource("podcast")}>Podcast</button>
        <button className={this.state.currentSource === "songs" ? "active" : "" } onClick={() => this.toggleSource("songs")}>Songs &amp; Releases</button>
      </div>
    </div>
    )
  }
};

export {Header};
