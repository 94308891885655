import * as React from "react";
import "./sidebar.scss";
import {NavItem} from "./navItem";

interface SidebarState {
  nav: any[],
  setFeature: any,
}

interface SidebarProps {
  setFeature: any,
}

class Sidebar extends React.Component<SidebarProps, SidebarState> {
  constructor(props: SidebarProps) {
    super(props);
    this.state = {
      nav: [
        {feature: "/", title: "Dashboard"},
        {feature: "/components", title: "Components"},
        {feature: "/connections", title: "Connections"},
        {feature: "/inventory", title: "Inventory"}
      ],
      setFeature: this.props.setFeature,
    };
  }

  render() {
    let navList = this.state.nav.map((item, index) => {
      return <NavItem key={index} feature={item.feature} title={item.title} />
    })

    return (<div className="sidebar">
      {navList}
      <hr />
      <NavItem key="bug" feature="/bugs" title="Report a Bug" />
    </div>);
  }
};

export {Sidebar};
