import * as React from "react";
import {
  Switch,
  Route,
} from "react-router-dom";
import {Dashboard} from "../dashboard/dashboard";

interface ContentState {
  active: null | string,
}

interface ContentProps {
  active: null | string,
}

class Content extends React.Component<ContentProps, ContentState> {
  constructor(props: ContentProps) {
    super(props);
    this.state = {
      active: this.props.active,
    };
  }

  setContent(feature: any) {
    this.setState({active: feature});
  }

  render() {
    return(<div className="content">
      <Switch>
        <Route exact path="/">
          <Dashboard />
        </Route>
      </Switch>
      </div>)
    };
};

export {Content};
