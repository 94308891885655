import React from 'react';
import {
  NavLink
} from "react-router-dom";
import './navItem.scss';
// Example Usage
// <NavItem key={index} feature={item.feature} title={item.title} navigate={this.navigate} />

interface NavItemState {
  feature: string,
  title: string
}

interface NavItemProps {
  feature: string,
  title: string
}

class NavItem extends React.Component<NavItemProps, NavItemState> {
  constructor(props: NavItemProps) {
    super(props);
    this.state = {
      feature: this.props.feature,
      title: this.props.title,
    };
  }

  render() {
    return (
      <div className="nav-item">
        <NavLink to={this.state.feature} exact={true} activeClassName="active">
          {this.state.title}
        </NavLink>
      </div>
    );
  }
};

export {NavItem};
